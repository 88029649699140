'use strict'

###*
 # @ngdoc service
 # @name mundoUtils.factory:TokenUtils

 # @description

###
angular
  .module 'mundoUtils'
  .factory 'TokenUtils', [
    '$http'
    '$httpParamSerializer'
    '$log'
    'mundoConfiguration'
    ($http, $httpParamSerializer, $log, mundoConfiguration) ->
      TokenUtilsBase = {}

      TokenUtilsBase.getClientToken = () ->
        data =
          grant_type: "client_credentials"
          client_id: mundoConfiguration.oauth.clientId
        if (null != mundoConfiguration.oauth.clientSecret)
          data.client_secret = mundoConfiguration.oauth.clientSecret
        data = $httpParamSerializer(data)
        options =
          headers:
            "Content-Type": "application/x-www-form-urlencoded"
        $http.post("" + mundoConfiguration.oauth.baseUrl + "" + mundoConfiguration.oauth.grantPath, data, options)
        .then (response) ->
          return response

      TokenUtilsBase
  ]
